import { KeepStencilOp, Vector3 } from "three";

export function mod(v, n) {
    return ((v % n) + n) % n;
}

export function modV3(v, n) {
    return v.set(
        mod(v.x, n),
        mod(v.y, n),
        mod(v.z, n)
    )
}

export function modAround(v: Vector3, v2: Vector3, boxSize: number, boxSize2: number) {
    return modV3(v.sub(v2).addScalar(boxSize2), boxSize).subScalar(boxSize2).add(v2)
}

export function hasCollision(p, r, ps, rs, boxSize, boxSizeHalf) {
    for (let i = 0; i < ps.length; i++) {
        const temp = ps[i].clone().sub(p).addScalar(boxSizeHalf)
        modV3(temp, boxSize).subScalar(boxSizeHalf)
        if (temp.length() < rs[i] + r) return true

    }
    return false
}

export function getCollisions(object: any, objects: any[], boxSize: number, boxSize2: number) {
    const collisions = []
    for (let i = 0; i < objects.length; i++) {
        const temp = objects[i].position.clone().sub(object.position).addScalar(boxSize2)
        modV3(temp, boxSize).subScalar(boxSize2)
        const distance = temp.length() - objects[i].userData.radius + object.userData.radius
        if (distance < 0) {
            let j = 0
            for (j = 0; j < collisions.length; j++) {
                if (collisions[j].distance > distance) break
            }
            collisions.splice(j, 0, { distance, object: objects[i], normal: temp.clone().normalize() })
        }
    }
    return collisions
}

export function hasCol(p, r, os, boxSize, boxSizeHalf) {
    for (let i = 0; i < os.length; i++) {
        const temp = os[i].position.clone().sub(p).addScalar(boxSizeHalf)
        modV3(temp, boxSize).subScalar(boxSizeHalf)
        if (temp.length() < os[i].userData.radius + r) return true

    }
    return false
}
export function hasCol2(o, os, boxSize) {
    for (let i = 0; i < os.length; i++) {
        const temp = os[i].position.clone().sub(o.position).addScalar(boxSize / 2)
        modV3(temp, boxSize).subScalar(boxSize / 2)
        if (temp.length() < os[i].radius + o.radius) return os[i]

    }
    return false
}

export function randomBox(v, rand, size) {
    return v.set(rand(), rand(), rand()).multiplyScalar(size)
}

export function randBox(size = 1, v = new Vector3(), rand = Math.random) {
    return v.set(rand(), rand(), rand()).multiplyScalar(size)
}

export function randomSphere(v, rand, radius) {
    randomBox(v, rand, radius * 2).subScalar(radius)
    while (v.length() > radius) randomBox(v, rand, radius * 2).subScalar(radius)
    return v
}

export function keysApply(keys, buttons, stg, ignoreShift = false) {
    const allkeys = { ...keys }
    Object.keys(buttons).forEach(b => allkeys["Mouse" + b] = true)
    if (!ignoreShift && allkeys["ShiftLeft"] && stg.indexOf("ShiftLeft") === -1) return false
    let miss = false
    stg.forEach(v => {
        if (!allkeys[v]) miss = true
    })
    return !miss
}

export function colorFromType(type: number) {
    return [0xbfbfbf, 0x7FFF00, 0x007FFF, 0xff7f00, 0xff007f][type]
}

export function iconFromType(type: number) {
    return ["sp", "fuel", "missiles", "ammo", "coins"][type]
}

export function textFromType(type: number) {
    return ["x 50", "x 25", "x 2", "x 50", "x 50"][type]
}

export function nameFromType(type: number) {
    return ["shield", "fuel", "missiles", "bullets", "coins"][type]
}


export function time_of_impact(px: number, py: number, pz: number, vx: number, vy: number, vz: number, s: number): number {
    let a = s * s - (vx * vx + vy * vy + vz * vz);
    let b = px * vx + py * vy + pz * vz;
    let c = px * px + py * py + pz * pz;

    let d = b * b + a * c;

    let t = 0;
    if (d >= 0) {
        t = (b + Math.sqrt(d)) / a;
        if (t < 0)
            t = 0;
    }

    return t;
}