import { useEffect, useRef, useState } from "react"
import ClientGame from "../ClientGame"
import BlubPackage, { MakeClientPacket } from "./BlubPackage"

export default function useGameNetwork(settings, restURL, wsURL, device) {

    const wsRef = useRef<WebSocket>()

    const [state, setState] = useState(() => ({
        socketStatus: -2,
        errorMsg: "",
        playerID: -1,
        myShip: null,
        players: [],
        bots: [],
        gameID: -1,
        fogColor: "#124",
        game: new ClientGame(settings, send, device),
        wsRef
    }))

    useEffect(() => {

        /*
        check if webglrenderer was created...
        bessere lösung finden bitte danke
        */

        function checkDisplay() {

            if (state.game.display) {
                fetch(`${restURL}/getgame`).then(res => res.json()).then(json => {
                    setState(state => ({ ...state, gameID: json.id }))
                }, (reason: any) => {
                    setState(state => ({ ...state, errorMsg: "unable to connect to lobby" }))
                })
            } else setTimeout(checkDisplay, 100)

        }

        checkDisplay()

    }, [setState])

    useEffect(() => {

        if (state.gameID === -1) return

        let ws = new WebSocket(`${wsURL}/game/${state.gameID}?transport=websocket`)
        ws.binaryType = "arraybuffer"
        ws.addEventListener("open", setStatus)
        ws.addEventListener("close", setStatus)
        ws.addEventListener("error", setStatus)
        ws.addEventListener("message", onmessage)

        function setStatus() {
            setState(state => ({
                ...state,
                socketStatus: ws.readyState,
                errorMsg: state.socketStatus === -2 && ws.readyState === 3 ? "unable to connect to game server" : ws.readyState === 3 ? "lost connection to game server" : state.errorMsg
            }))
        }

        function onmessage(e) {
            let m = BlubPackage(new DataView(e.data))
            state.game.processMessage(m, ws)
        }

        const interval = setInterval(() => {
            if (state.game.myShip) send(MakeClientPacket.UpdateShip(state.game.myShip, state.game.estServerTime + 500))
        }, 100)

        wsRef.current = ws

        return () => {
            if (ws) {
                ws.close()
                ws.removeEventListener("open", setStatus)
                ws.removeEventListener("close", setStatus)
                ws.removeEventListener("error", setStatus)
                ws.removeEventListener("message", onmessage)
                clearInterval(interval)
                wsRef.current = undefined
            }
        }

    }, [state.gameID, setState, wsRef])

    function play(name) {
        send(MakeClientPacket.Join(name))
    }
    function leave() {
        send(MakeClientPacket.Leave())
    }
    function send(msg) {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(msg)
        }
    }

    return { wsRef, play, leave, send, state, setState }

}