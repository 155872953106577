import { Quaternion, Vector3 } from "three"
import Physics from "./physics/physics"

class Player {
    constructor(public id: number) { }
}

class Bot {
    constructor(public id: number) { }
}

class Ship {
    constructor(public id: number, public controller: number) { }
    onCollision:Function
}

class Loot {
    constructor(public id: number) { }
}

class Projectile {
    constructor(public id: number) { }
}

class Missile {
    constructor(public id: number) { }
}

export default class Game {

    serverTime = { local: 0, server: 0 }
    runtime:0
    players = []
    bots = []
    ships = []
    explosions = []
    projectiles = []
    missiles = []
    loots = []

}