
const Configs = {

    GunShip: [{
        type: "Seat",
        position: [0, 0.25, 0]
    }, {
        type: "Capsule",
        scale: [1, 1, 1],
        position: [0, 0, 0],
        rotation: [0, 0, 0]
    }, {
        type: "Engine",
        position: [-1.5, 0, 1.5],
        rotation: [0, 0, 0]
    }, {
        type: "Engine",
        position: [1.5, 0, 1.5],
        rotation: [0, 0, 0]
    }, {
        type: "MissileLauncher",
        position: [-1.25, 0.5, -0.5]
    }, {
        type: "MissileLauncher",
        position: [1.25, 0.5, -0.5]
    }, {
        type: "Gun",
        position: [-0.5, -0.5, -1],
        rotation: [0, 0, -Math.PI]
    }, {
        type: "Gun",
        position: [0.5, -0.5, -1]
    }, {
        type: "Lamp",
        position: [-1, 0, -0.5]
    }, {
        type: "Lamp",
        position: [1, 0, -0.5]
    }, {
        type: "Shield",
        position: [0, 0, 0]
    }],

    MissileFrigate: [{
        type: "Seat"
    }, {
        type: "Capsule",
        scale: [3, 2, 6],
        position: [0, 0, 0],
        rotation: [0, 0, 0]
    }, {
        type: "Engine",
        position: [-1.5, 0, 2],
        rotation: [0, 0, 0]
    }, {
        type: "Engine",
        position: [1.5, 0, 2],
        rotation: [0, 0, 0]
    }, {
        type: "Engine",
        position: [0, -1.25, 2],
        rotation: [0, 0, 0]
    }, {
        type: "Engine",
        position: [0, 1.25, 2],
        rotation: [0, 0, 0]
    }, {
        type: "MissileLauncher",
        position: [-1.25, 0.5, -0.5]
    }, {
        type: "MissileLauncher",
        position: [1.25, 0.5, -0.5]
    }, {
        type: "Gun",
        position: [-0.75, -0.5, -1],
        rotation: [0, 0, -Math.PI]
    }, {
        type: "Gun",
        position: [0.75, -0.5, -1]
    }, {
        type: "Lamp",
        position: [0, 1, -0.5]
    }, {
        type: "Lamp",
        position: [0, -1, -0.5]
    }, {
        type: "Lamp",
        position: [-1, 0, -0.5]
    }, {
        type: "Lamp",
        position: [1, 0, -0.5]
    }, {
        type: "Shield",
        position: [0, 0, 0]
    }]

}

export default Configs