import { useFrame } from "@react-three/fiber"
import { createElement, useEffect, useRef } from "react"
import { BackSide, BufferGeometry, CylinderBufferGeometry, DoubleSide, FrontSide, IcosahedronBufferGeometry, Mesh, MeshStandardMaterial, Object3D, SphereBufferGeometry, Vector3 } from "three"
import Configs from "./ShipConfigs"

import { BufferGeometryUtils } from "three/examples/jsm/utils/BufferGeometryUtils"
import Shield, { ShieldMaterial2 } from "./RAGE/gameobjects/ShipModules/Shield"
import { forwardRef } from "react"

const blackMetalMaterial = new MeshStandardMaterial({ color: "#000", dithering: true, metalness: 0.75, roughness: 0.5, flatShading: true, side: DoubleSide })
const whiteCompoundMaterial = new MeshStandardMaterial({ color: "#fff", dithering: true, metalness: 0.75, roughness: 0.5, flatShading: true, side: DoubleSide })
const blackCompoundMaterial = new MeshStandardMaterial({ color: "#000", dithering: true, metalness: 0.25, roughness: 0.75, flatShading: true, side: DoubleSide })
const blackGlassMaterial = new MeshStandardMaterial({ color: "black", roughness: 0, metalness: 0, transparent: true, opacity: 0.9, dithering: true })

const ShipPrefab = forwardRef((props: any, ref: any) => {

    return <group ref={ref} visible={false}>
        {Configs.GunShip.map((module, idx) => <Module key={idx} {...module} />)}
    </group>
})

export default ShipPrefab


function Module(props) {

    return createElement(Modules[props.type], props)

}

const Modules = {
    Engine: ({ rotation, position }) => {

        const ref = useRef<Object3D>()

        useEffect(() => {

            const mesh = new Mesh(engineGeometry, blackMetalMaterial)
            ref.current && ref.current.add(mesh)

        }, [ref])

        return <group ref={ref} rotation={rotation} position={position} scale={[1, 1, 1]}>
            <mesh layers={4} position={[0, 0, -0.0]} scale={[0.23, 0.6, 0.23]} rotation={[-Math.PI / 2, 0, 0]}>
                <cylinderBufferGeometry args={[2, 2, 2]} />
                <meshBasicMaterial color="#f93" />
            </mesh>
            <mesh position={[0, 0, 0.25]}>
                <icosahedronBufferGeometry args={[0.1, 3]} />
                <pointLight visible={false} layers={2} position={[0, 0, 0]} args={["#f93", 1, 1, 1]} />
            </mesh>
            <mesh position={[0, 0, -0.25]}>
                <icosahedronBufferGeometry args={[0.1, 3]} />
                <pointLight visible={false} layers={2} position={[0, 0, 0]} args={["#f93", 0.1, 5, 1]} />
            </mesh>
        </group>
    },
    Capsule: ({ rotation, position, scale }) => {

        const ref = useRef<Object3D>()

        useEffect(() => {
            const mesh = new Mesh(cockpitGeometry, blackMetalMaterial)
            ref.current && ref.current.add(mesh)
            const glass = new Mesh(cockpitGlassGeometry, blackGlassMaterial)
            ref.current && ref.current.add(glass)
        }, [])

        return <group ref={ref} rotation={rotation} position={position} scale={scale}></group>
    },

    MissileLauncher: ({ position }) => {
        return <group position={position}>
            {new Array(4).fill(0, 0, 4).map((v, i) => {
                const position = new Vector3(Math.sin((i + 0.5) * Math.PI * 0.5) * 0.15, Math.cos((i + 0.5) * Math.PI * 0.5) * 0.15, 0)
                return <Missile key={i} position={position} />
            }
            )}
        </group>
    },
    Gun: ({ position, rotation }) => {
        return <group position={position} rotation={rotation}>
            {new Array(3).fill(0, 0, 3).map((v, i) =>
                <mesh position={[Math.sin(i * Math.PI * 2 / 3) * 0.02, Math.cos(i * Math.PI * 2 / 3) * 0.02, -0.5]} key={i} args={[cylinderGeometry, blackMetalMaterial]} scale={[0.02, 0.02, 1]} />
            )}
            <mesh scale={1 / 3} args={[capsuleGeometry, blackMetalMaterial]} />
        </group>
    },
    Lamp: ({ position }) => {
        return <group position={position}>
            <mesh scale={[0.1, 0.1, 0.5]} args={[cylinderGeometry, whiteCompoundMaterial]} />
            <spotLight visible={false} layers={2} onUpdate={light => {
                light.target = new Object3D()
                light.target.position.set(0, 0, -1)
                light.add(light.target)
            }} args={[0xffffff, 1, 100, Math.PI / 6, 0, 1]} />
            <mesh position={[0, 0, -0.25]} scale={[0.04, 0.01, 0.04]} rotation={[-Math.PI / 2, 0, 0]}>
                <cylinderBufferGeometry />
                <meshBasicMaterial color="#fff" />
            </mesh>
        </group>
    },
    Shield: () => {
        const ref = useRef<Object3D>()

        useEffect(() => {
            const geo = new IcosahedronBufferGeometry(5, 1)
            geo.computeVertexNormals()
            if (ref.current) ref.current.add(new Shield(geo))
        }, [ref])

        return <group ref={ref} >

        </group>

    },
    Seat: ({ position }) => {

        return <group position={position}>
            <mesh visible={true} scale={[0.6, 1.3, 1.675]}>
                <boxBufferGeometry />
                <meshBasicMaterial color="green" transparent opacity={0.1} />
            </mesh>
        </group>

    }
}

function Missile({ position }) {

    return <group position={position}>
        <mesh castShadow receiveShadow
            scale={[0.25, 0.25, 1]}
            args={[engineBaseGeometry, blackMetalMaterial]}
        />
        <mesh castShadow receiveShadow
            scale={[0.25, 0.25, 0.25]}
            position={[0, 0, 0.25 + 0.005]}
            args={[nozzleGeometry, blackMetalMaterial]}
        />
        <mesh castShadow receiveShadow
            scale={[0.0625, 0.0625, 0.25 / 2]}
            position={[0, 0, -0.25 - 0.005]}
            args={[halfSphereGeometry, blackMetalMaterial]}
        />
        <mesh layers={4} position={[0, 0, -0.0]} scale={[0.0625 - 0.01, 0.55, 0.0625 - 0.01]} rotation={[-Math.PI / 2, 0, 0]}>
            <cylinderBufferGeometry />
            <meshBasicMaterial color="#39f" />
        </mesh>
    </group>



    //<mesh position={position} args={[cylinderGeometry, blackMetalMaterial]} scale={[0.15, 0.15, 1]} />


}



const cockpitBaseBTGeometry = new SphereBufferGeometry(0.5, 32, 6, 0, Math.PI, Math.PI * 0.5, Math.PI * 0.5)
cockpitBaseBTGeometry.rotateX(-Math.PI / 2)

const cockpitBaseBBGeometry = new SphereBufferGeometry(0.5, 32, 6, Math.PI, Math.PI, Math.PI * 0.5, Math.PI * 0.5)
cockpitBaseBBGeometry.rotateX(-Math.PI / 2)
cockpitBaseBBGeometry.scale(1, 0.5, 1)

const cockpitBase2Geometry = new SphereBufferGeometry(0.5, 8, 8, Math.PI, Math.PI, 0, Math.PI / 4)
const cockpitGlassGeometry = new SphereBufferGeometry(0.5, 24, 8, Math.PI, Math.PI, Math.PI / 4, Math.PI / 4)
cockpitGlassGeometry.scale(2, 2, 6)
const cockpitBase3Geometry = new SphereBufferGeometry(0.5, 64, 8, Math.PI, Math.PI, Math.PI / 4 + Math.PI / 4, Math.PI / 2)
cockpitBase3Geometry.scale(1, 0.5, 1)
const cylinderGeometry = new CylinderBufferGeometry(0.5, 0.5, 1, 32, 1, false)
cylinderGeometry.rotateX(-Math.PI / 2)

const capsuleGeometry = new IcosahedronBufferGeometry(0.5, 7)


const nozzleGeometry = new CylinderBufferGeometry(0.25, 0.125, 1 / 3, 32, 1, true)
nozzleGeometry.rotateX(-Math.PI / 2)
nozzleGeometry.translate(0, 0, 0.5 / 3)

const engineBaseGeometry = new CylinderBufferGeometry(0.25, 0.25, 0.5, 32)
engineBaseGeometry.rotateX(-Math.PI / 2)

const engineNozzleGeometry = new CylinderBufferGeometry(0.25, 0.125, 1 / 3, 32, 1, true)
engineNozzleGeometry.rotateX(-Math.PI / 2)
engineNozzleGeometry.translate(0, 0, 0.5 / 3)
engineNozzleGeometry.translate(0, 0, 0.27)

const halfSphereGeometry = new SphereBufferGeometry(1, 16, 32, Math.PI, Math.PI)
const engineNoseGeometry = new SphereBufferGeometry(1, 16, 32, Math.PI, Math.PI)
engineNoseGeometry.scale(0.25, 0.25, 0.5)
engineNoseGeometry.translate(0, 0, -0.27)
const ico3Geometry = new IcosahedronBufferGeometry(1, 2)
ico3Geometry.computeVertexNormals()


const cockpitGeometry = BufferGeometryUtils.mergeBufferGeometries([
    cockpitBaseBBGeometry,
    cockpitBaseBTGeometry,
    cockpitBase2Geometry,
    cockpitBase3Geometry
])
cockpitGeometry.scale(2, 2, 6)

const engineGeometry = BufferGeometryUtils.mergeBufferGeometries([
    engineBaseGeometry,
    engineNozzleGeometry,
    engineNoseGeometry
])
engineGeometry.scale(2, 2, 2)

/*
            <mesh castShadow receiveShadow
                args={[engineGeometry, blackMetalMaterial]}
            />
            <mesh castShadow receiveShadow
                position={[0, 0, 0.25 + 0.02]}
                args={[nozzleGeometry, blackMetalMaterial]}
            />
            <mesh castShadow receiveShadow
                scale={[0.25, 0.25, 0.5]}
                position={[0, 0, -0.27]}
                args={[halfSphereGeometry, blackMetalMaterial]}
            />*/