import { useContext } from "react"
import { Form, Navbar } from "react-bootstrap"

export default function StatusBar({ state, children }) {

    const { socketStatus, playerID } = state

    return <Navbar className="justify-content-between" fixed="top" variant="dark" style={{ userSelect: "none" }}>
        {children}
        <Form inline style={{ display: "none" }}>
            <Navbar.Text>
                <pre className="text-light m-0">
                    socket: {socketStatus} | playerID: {playerID}
                </pre>
            </Navbar.Text>
        </Form>
    </Navbar>
}