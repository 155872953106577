import { AdditiveBlending, Color, DoubleSide, FrontSide, IcosahedronBufferGeometry, IUniform, Mesh, MeshBasicMaterial, MeshLambertMaterial, MeshStandardMaterial, ShaderMaterial, Vector3 } from "three";

const shieldGeometry = new IcosahedronBufferGeometry(2, 1)
shieldGeometry.computeVertexNormals()

export default class Shield extends Mesh {

    constructor(geometry = shieldGeometry) {
        super(geometry, new ShieldMaterial2())
        this.userData.isArea = true
    }

}

export class ShieldMaterial2 extends ShaderMaterial {

    hitIndex = 0
    energy = 1

    constructor(public maxHits = 16) {
        super({
            uniforms: {
                time: { value: 0 },
                energy: { value: 1 },
                hitPositions: { value: new Float32Array(maxHits * 3) },
                hitTimes: { value: new Float32Array(maxHits) },
                fogColor: { value: new Color(0x000000) },
                fogNear: { value: 0 },
                fogFar: { value: 50000 },
                opacity: { value: 1 }
            },
            fog: true,
            dithering: true,
            blending: AdditiveBlending,
            depthWrite: false,
            side: DoubleSide,
            transparent: true,
            vertexShader: `
            varying vec3 vCamPosition;
            varying vec3 vNormal;
            varying vec3 vPosition;
            #include <fog_pars_vertex>
            void main() {
                vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * mvPosition;
                vCamPosition = normalize(mvPosition.xyz - cameraPosition);
                vNormal = normalMatrix * normal;
                vPosition = position;
                #include <fog_vertex>
            }`,
            fragmentShader: `
            const int MAX_HITS = ${maxHits};
            uniform vec3[MAX_HITS] hitPositions;
            uniform float[MAX_HITS] hitTimes;
            uniform float time;
            uniform float energy;
            uniform vec3 diffuse;
            uniform float opacity;
            varying vec3 vCamPosition;
            varying vec3 vNormal;
            varying vec3 vPosition;
            #include <common>
            #include <dithering_pars_fragment>
            #include <color_pars_fragment>
            #include <fog_pars_fragment>
            void main() {
                /*float fogNear = 0.0;
                float fogFar = 500.0;
                vec3 fogColor = vec3(0);*/
                vec3 pNormal = -vNormal;
                vec3 tone = vec3(1.0 - energy, energy * 0.5, energy);

                float am = 0.0;
                for(int i = 0; i < MAX_HITS; i++) {
                    float deltaTime = time - hitTimes[i];
                    if(deltaTime > 0.0) {
                        vec3 hit = normalize(hitPositions[i]);
                        float dt = max(0.0, 1.0 - deltaTime);
                        float a = pow(max(0.0,1.0 - length(hit - normalize(vPosition))), 2.0) * dt;
                        if(dt > 0.8) a *= deltaTime * 5.0;
                        am = max(a, am);
                        am += a * 0.5;
                    }
                }

                gl_FragColor = vec4(tone, 1.0);
                gl_FragColor.rgb *= pow((max(1.0 - abs(pNormal.z),0.0)), 3.0) * pow(energy, 0.5);
                gl_FragColor.rgb += am*am*(tone+1.0) * 0.5;

                float fogFactor = smoothstep( fogNear, fogFar, fogDepth );
                gl_FragColor.rgb = mix( gl_FragColor.rgb, vec3(0), fogFactor );
                gl_FragColor.a *= opacity;
                #include <dithering_fragment>
            }`
        })
    }

    setTime(time: number) {
        this.uniforms.time.value = time
    }

    addHit(time: number, point: Vector3, damage: number) {
        this.uniforms.hitPositions.value[this.hitIndex * 3 + 0] = point.x
        this.uniforms.hitPositions.value[this.hitIndex * 3 + 1] = point.y
        this.uniforms.hitPositions.value[this.hitIndex * 3 + 2] = point.z
        this.uniforms.hitTimes.value[this.hitIndex] = time
        this.hitIndex++
        this.hitIndex = this.hitIndex % this.maxHits
        this.energy = Math.max(0, this.energy - damage)
        this.uniformsNeedUpdate = true
    }

}
/*
export function ShieldMaterial() {

    const maxHits = 16

    let hitIndex = 0
    let energy = 1

    const material = new MeshStandardMaterial({
        side: DoubleSide, depthWrite: false, emissive: 0x0099ff, dithering: true, color: 0x000000, blending: AdditiveBlending, transparent: true, opacity: 0
    }) as any

    material.addHit = function (time, point, damage) {
        if (!material.uniforms) return
        material.uniforms.hitPositions.value[hitIndex].copy(point).normalize()
        material.uniforms.hitTimes.value[hitIndex] = time
        hitIndex++
        hitIndex = hitIndex % maxHits
        energy = Math.max(0, energy - damage)
        material.uniforms.energy.value = energy
    }
    material.setTime = function (time) {
        if (!material.uniforms) return
        material.uniforms.time.value = time
        material.uniforms.time.needsUpdate = true
    }

    const sm = material as MeshStandardMaterial

    sm.onBeforeCompile = (shader, renderer) => {

        shader.vertexShader = `
      #define STANDARD
      varying vec3 vViewPosition;
      #ifndef FLAT_SHADED
          varying vec3 vNormal;
          #ifdef USE_TANGENT
              varying vec3 vTangent;
              varying vec3 vBitangent;
          #endif
      #endif
      #include <common>
      #include <uv_pars_vertex>
      #include <uv2_pars_vertex>
      #include <displacementmap_pars_vertex>
      #include <color_pars_vertex>
      #include <fog_pars_vertex>
      #include <morphtarget_pars_vertex>
      #include <skinning_pars_vertex>
      #include <shadowmap_pars_vertex>
      #include <logdepthbuf_pars_vertex>
      #include <clipping_planes_pars_vertex>
      varying vec4 vColor;
      const int MAX_HITS = ${maxHits};
      uniform vec3[MAX_HITS] hitPositions;
      uniform float[MAX_HITS] hitTimes;
      uniform float time;
      uniform float energy;
      varying vec3 vPosition;
      varying vec3 vCamPosition;
      void main() {
          #include <uv_vertex>
          #include <uv2_vertex>
          #include <color_vertex>
          #include <beginnormal_vertex>
          #include <morphnormal_vertex>
          #include <skinbase_vertex>
          #include <skinnormal_vertex>
          #include <defaultnormal_vertex>
      #ifndef FLAT_SHADED
          vNormal = normalize( transformedNormal );
          #ifdef USE_TANGENT
              vTangent = normalize( transformedTangent );
              vBitangent = normalize( cross( vNormal, vTangent ) * tangent.w );
          #endif
      #endif
          #include <begin_vertex>
          #include <morphtarget_vertex>
          #include <skinning_vertex>
          #include <displacementmap_vertex>
          #include <project_vertex>
          #include <logdepthbuf_vertex>
          #include <clipping_planes_vertex>
          vViewPosition = - mvPosition.xyz;
          #include <worldpos_vertex>
          #include <shadowmap_vertex>
          #include <fog_vertex>
          vCamPosition = normalize(mvPosition.xyz - cameraPosition);
          vPosition = position;

      }
  `

        shader.fragmentShader = `
  #define STANDARD
      #ifdef PHYSICAL
          #define REFLECTIVITY
          #define CLEARCOAT
          #define TRANSMISSION
      #endif
      uniform vec3 diffuse;
      uniform vec3 emissive;
      uniform float roughness;
      uniform float metalness;
      uniform float opacity;
      varying vec3 vPosition;
      varying vec3 vCamPosition;
      #ifdef TRANSMISSION
          uniform float transmission;
      #endif
      #ifdef REFLECTIVITY
          uniform float reflectivity;
      #endif
      #ifdef CLEARCOAT
          uniform float clearcoat;
          uniform float clearcoatRoughness;
      #endif
      #ifdef USE_SHEEN
          uniform vec3 sheen;
      #endif
      varying vec3 vViewPosition;
      #ifndef FLAT_SHADED
          varying vec3 vNormal;
          #ifdef USE_TANGENT
              varying vec3 vTangent;
              varying vec3 vBitangent;
          #endif
      #endif
      #include <common>
      #include <packing>
      #include <dithering_pars_fragment>
      #include <color_pars_fragment>
      #include <uv_pars_fragment>
      #include <uv2_pars_fragment>
      #include <map_pars_fragment>
      #include <alphamap_pars_fragment>
      #include <aomap_pars_fragment>
      #include <lightmap_pars_fragment>
      #include <emissivemap_pars_fragment>
      #include <transmissionmap_pars_fragment>
      #include <bsdfs>
      #include <cube_uv_reflection_fragment>
      #include <envmap_common_pars_fragment>
      #include <envmap_physical_pars_fragment>
      #include <fog_pars_fragment>
      #include <lights_pars_begin>
      #include <lights_physical_pars_fragment>
      #include <shadowmap_pars_fragment>
      #include <bumpmap_pars_fragment>
      #include <normalmap_pars_fragment>
      #include <clearcoat_pars_fragment>
      #include <roughnessmap_pars_fragment>
      #include <metalnessmap_pars_fragment>
      #include <logdepthbuf_pars_fragment>
      #include <clipping_planes_pars_fragment>
      varying vec4 vColor;
      const int MAX_HITS = ${maxHits};
      uniform vec3[MAX_HITS] hitPositions;
      uniform float[MAX_HITS] hitTimes;
      uniform float time;
      uniform float energy;
      void main() {
          #include <clipping_planes_fragment>
          vec4 diffuseColor = vec4( diffuse, opacity );
          ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
          vec3 totalEmissiveRadiance = emissive;
          #ifdef TRANSMISSION
              float totalTransmission = transmission;
          #endif
          #include <logdepthbuf_fragment>
          #include <map_fragment>
          #include <color_fragment>
          #include <alphamap_fragment>
          #include <alphatest_fragment>
          #include <roughnessmap_fragment>
          #include <metalnessmap_fragment>
          #include <normal_fragment_begin>
          #include <normal_fragment_maps>
          #include <clearcoat_normal_fragment_begin>
          #include <clearcoat_normal_fragment_maps>
          #include <emissivemap_fragment>
          #include <transmissionmap_fragment>
          #include <lights_physical_fragment>
          #include <lights_fragment_begin>
          #include <lights_fragment_maps>
          #include <lights_fragment_end>
          #include <aomap_fragment>
          vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;
          #ifdef TRANSMISSION
              diffuseColor.a *= mix( saturate( 1. - totalTransmission + linearToRelativeLuminance( reflectedLight.directSpecular + reflectedLight.indirectSpecular ) ), 1.0, metalness );
          #endif

          float am = 0.0;
          for(int i = 0; i < MAX_HITS; i++) {
              float deltaTime = time - hitTimes[i];
              if(deltaTime > 0.0) {
                  vec3 hit = normalize(hitPositions[i]);
                  float dt = max(0.0, 1.0 - deltaTime);
                  float a = pow(max(0.0,1.0 - length(hit - normalize(vPosition))), 4.0) * dt;
                  if(dt > 0.8) a *= deltaTime * 5.0;
                  //am = max(a, am);
                  am += a * 0.5;
              }
          }

          vec3 pNormal = -vNormal;

          vec3 tone = vec3(1.0 - energy, energy * 0.5, energy);

          float intensity = min(max(0.0,pow(1.0 - dot(vCamPosition, pNormal), 2.0) * 0.1), 1.0);
          gl_FragColor = vec4(tone, 1.0);
          gl_FragColor.rgb *= pow((max(1.0 - abs(pNormal.z),0.0)), 3.0) * pow(energy, 0.5);
          gl_FragColor.rgb += am*am*(tone+1.0) * 10.0;

          #include <tonemapping_fragment>
          #include <encodings_fragment>
         // #include <fog_fragment>
         float fogFactor = smoothstep( fogNear, fogFar, fogDepth );
         #include <premultiplied_alpha_fragment>
          #include <dithering_fragment>
          gl_FragColor.a *= 1.0 - fogFactor;
      }
  `

        const hitPositions32 = new Float32Array(maxHits * 3)
        const hitTimes32 = new Float32Array(maxHits)
        const hitPositions = []
        const hitTimes = []

        for (let i = 0; i < maxHits; i++) {
            hitTimes32[i] = 0
            const pos = new Vector3(Math.random(), Math.random(), Math.random()).subScalar(0.5).normalize()
            hitPositions32[i * 3 + 0] = pos.x
            hitPositions32[i * 3 + 1] = pos.y
            hitPositions32[i * 3 + 2] = pos.z
            hitPositions[i] = pos
            hitTimes[i] = -10
        }

        material.uniforms = shader.uniforms
        material.uniforms.hitPositions = { value: hitPositions }
        material.uniforms.hitTimes = { value: hitTimes }
        material.uniforms.time = { value: 0 }
        material.uniforms.energy = { value: 1 }
        material.uniforms.hitPositions.needsUpdate = true

    }

    return material

}
*/