import React, { createContext, useEffect, useRef, useState } from "react";
import Leaderboard from "./Components/Leaderboard";
import PortalForm from "./Components/PortalForm";
import ShipUI from "./Components/ShipUI";
import StatusBar from "./Components/StatusBar";
import ErrorDisplay from "./Components/ErrorDisplay";
import { BackSide, Color, DoubleSide, FloatType, Fog, HalfFloatType, IntType, LinearEncoding, LinearMipMapLinearFilter, Mesh, NoToneMapping, Object3D, OrthographicCamera, PCFSoftShadowMap, PerspectiveCamera, PointLight, RGBAFormat, RGBFormat, Scene, TextureDataType, Vector3, WebGLRenderTarget, WebGLShadowMap } from "three";
import { Button, Form, FormControl } from "react-bootstrap";
import useGameNetwork from "./RAGE/networking/useGameNetwork";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { ResizeObserver } from "@juggle/resize-observer";
import Settings, { defaultSettings } from "./Components/Settings";
import IconSVGToTexture from "./Components/IconSVGToTexture";
import DamageIndicator from "./Components/DamageIndicator";
import { BOX_SIZE, BOX_SIZE2 } from "./config";
import Stats from 'three/examples/jsm/libs/stats.module'
import MobileControls from "./Components/MobileControls";
import { Suspense } from "react";
import { EffectComposer, SMAA, DepthOfField, Bloom, SelectiveBloom, SSAO, Vignette, GodRays } from '@react-three/postprocessing'
import { BlurPass, Resizer, KernelSize, BlendFunction } from 'postprocessing'
import { GiCancel, GiExitDoor } from "react-icons/gi";
import { GoMute, GoUnmute } from "react-icons/go";
import { listener } from "./RAGE/ClientGame";
import ShipPrefab from "./ShipPrefab";



export default function App({ restURL, wsURL, device }) {

    const [soundEnabled, setSoundEnabled] = useState(true)
    const [settings, setSettings] = useState(defaultSettings)
    const { play, leave, state, setState } = useGameNetwork(settings, restURL, wsURL, device)
    const extrastyles = { cursor: state.myShip ? "url('crosshair24.png') 12 12, crosshair" : "auto" }

    useEffect(() => {
        listener.gain.gain.value = soundEnabled ? 1 : 0
        listener.setMasterVolume(soundEnabled ? 1 : 0)
    }, [soundEnabled])

    useEffect(() => {
        state.game.setSettings(settings)
    }, [settings])

    const messageBoi = useRef<any>()

    return <div style={{ height: "100%", position: "fixed", width: "100%" }}>

        <div style={{ ...extrastyles, height: "100%" }} onContextMenu={e => { e.preventDefault() }}>
            <Canvas dpr={window.devicePixelRatio} flat linear shadows={false} gl={{ antialias: false, stencil: false, depth: true, alpha: false }} resize={{ polyfill: ResizeObserver }} camera={{ fov: 60, far: BOX_SIZE * 100, near: 0.1 }} onCreated={r3f => {
                r3f.camera.layers.enableAll()
                r3f.camera.position.set(BOX_SIZE2, BOX_SIZE2, BOX_SIZE2)
            }}>
                <GameLoop state={state} setState={setState} messageBoi={messageBoi} settings={settings} />
            </Canvas>
        </div>

        <div style={{ position: "fixed", top: 10, left: 10, userSelect: "none" }}>
            {state.playerID !== -1 && <span><Button variant="dark" tabIndex={-1} onClick={e => { leave() }}>Exit</Button>&nbsp;</span>}
            <Settings settings={settings} setSettings={setSettings} />&nbsp;
            <Button variant="dark" tabIndex={-1} onClick={e => {
                (e.target as HTMLButtonElement).blur()
                setSoundEnabled(se => !se)
            }}>{soundEnabled ? <GoUnmute style={{ pointerEvents: "none" }} /> : <GoMute style={{ pointerEvents: "none" }} />}</Button>
        </div>

        {<StatusBar state={state}>
            <Form inline>

            </Form>
        </StatusBar>}
        {state.myShip && <ShipUI myShip={state.myShip} device={device} />}
        {state.myShip && (device.isMobile || device.isTablet) && <MobileControls state={state} />}
        {state.playerID !== -1 && (!device.isMobile && !device.isTablet) && <Leaderboard state={state} />}
        <PortalForm onSubmit={play} state={state} />
        <IconSVGToTexture />
        <Messages handle={messageBoi} />
        <ErrorDisplay error={state.errorMsg} />
    </div>
}

const Messages = React.memo((props: any) => {

    const maxMessages = 10

    const [messages, setMessages] = useState([])
    const [alerts, setAlerts] = useState([])

    useEffect(() => {
        props.handle.current = { setMessages, setAlerts }
    }, [props.handle, setMessages, setAlerts])

    useEffect(() => {
        if (messages.length > maxMessages) {
            setMessages(messages => {
                while (messages.length > maxMessages) messages.shift()
                return [...messages]
            })
        }
    }, [messages, setMessages])

    return <div style={{ position: "absolute", width: "100vw", height: "100vh", top: 0, left: 0, pointerEvents: "none", color: "white", userSelect: "none" }}>
        <div style={{ bottom: "160px", position: "absolute", left: "1em" }}>
            {messages.map((m, i) =>
                <div key={i}>{m}</div>
            )}
        </div>
    </div>

})


function GameLoop({ state, setState, messageBoi, settings }) {

    const shipPrefab = useRef<Object3D>()
    const bgColor = 0x102040//0x202020//0x102040//0x081020//0x090909
    const color = useRef<Color>()
    const fog = useRef<Fog>()
    const { scene, camera, gl, setDpr } = useThree(({ scene, camera, gl, setDpr }) => ({ scene, camera, gl, setDpr }))
    const stats = useRef<any>()
    useEffect(() => {
        state.game.setR3F({ gl, scene, camera, setState, messageBoi, shipPrefab: shipPrefab.current })
    }, [gl, scene, camera, setState, state.game, messageBoi, shipPrefab])

    useEffect(() => {
        stats.current = Stats()
        document.body.appendChild(stats.current.dom)
        stats.current.dom.style.top = "63px"
        stats.current.dom.style.left = "10px"
    }, [stats])

    useEffect(() => {
        const dpr_mult = settings.graphics.resolution === "High" ? 1 :
            settings.graphics.resolution === "Medium" ? 0.5 :
                settings.graphics.resolution === "Low" ? 0.25 : 1
        setDpr(window.devicePixelRatio * dpr_mult)
    }, [setDpr, settings])

    useFrame(({ camera, mouse }) => {
        // stats.current.begin()
        state.game.animate()
        // stats.current.end()
        stats.current.update()

    })

    return <>
        <fog ref={fog} attach="fog" args={[bgColor, 0, BOX_SIZE2]} />
        <color ref={color} attach="background" args={[bgColor]} />
        <ShipPrefab position={[BOX_SIZE2, BOX_SIZE2, BOX_SIZE2 - 20]} ref={shipPrefab} />
        <WTF />
        <mesh scale={BOX_SIZE * 50} visible={true}>
            <icosahedronBufferGeometry />
            <meshBasicMaterial side={BackSide} color={bgColor} dithering fog={true} />
        </mesh>
    </>
}

function Effects() {

    const size = useThree(state => state.size)
    const three = useThree(state => state)

    const getPixelRatio = useThree(state => state.gl.getPixelRatio)

    let type = HalfFloatType

    if (navigator.userAgent.toLowerCase().indexOf("12.0 mobile") !== -1 &&
        navigator.userAgent.toLowerCase().indexOf("12.0 mobile") !== -1) {
        type = undefined
    }

    const renderTargets = useRef({
        rt1: new WebGLRenderTarget(size.width * getPixelRatio(), size.height * getPixelRatio(), { type, format: RGBAFormat }),
        rt2: new WebGLRenderTarget(size.width * getPixelRatio(), size.height * getPixelRatio(), { type, format: RGBAFormat })
    })

    const planeScene = useRef<Scene>()
    const planeCamera = useRef<OrthographicCamera>()

    useEffect(() => {
        console.log(three)
    }, [three])

    useEffect(() => {
        renderTargets.current.rt1.setSize(size.width * getPixelRatio(), size.height * getPixelRatio())
        renderTargets.current.rt2.setSize(size.width * getPixelRatio(), size.height * getPixelRatio())
    }, [size, renderTargets])

    useFrame(({ gl, scene, camera }) => {
        if (planeScene.current && planeCamera.current) {
            planeScene.current.visible = false
            gl.setRenderTarget(renderTargets.current.rt1)
            gl.render(scene, camera)

            planeScene.current.visible = true
            gl.setRenderTarget(null)
            gl.render(planeScene.current, planeCamera.current)
        }

    }, 1)

    return <>

        <scene ref={planeScene}>
            <orthographicCamera ref={planeCamera} args={[-0.5, 0.5, 0.5, -0.5, -1, 1]} />
            <mesh>
                <planeBufferGeometry />
                <shaderMaterial
                    uniforms={{
                        rt1: { value: renderTargets.current.rt1.texture },
                        rt2: { value: renderTargets.current.rt2.texture }
                    }}
                    vertexShader={`
                        varying vec2 vUv;
                        void main() {
                            vUv = uv;
                            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                        }
                    `}
                    fragmentShader={`
                        varying vec2 vUv;
                        uniform sampler2D rt1;
                        uniform sampler2D rt2;
                        void main() {
                            gl_FragColor = texture2D(rt1, vUv);
                            gl_FragColor += texture2D(rt2,vUv);
                        }
                    `}
                />
            </mesh>
        </scene>

    </>

}

function WTF() {

    const ecRef = useRef<any>()
    const viewport = useThree(state => state.viewport)
    const size = useThree(state => state.size)


    useEffect(() => {

        if (ecRef.current) {

            ecRef.current.setSize(size.width, size.height)

        }

    }, [viewport.dpr, ecRef, size])

    return <Suspense fallback={null}>
        <EffectComposer ref={ecRef} frameBufferType={HalfFloatType}>
            <SelectiveBloom
                lights={[new PointLight()]} // ⚠️ REQUIRED! all relevant lights
                selectionLayer={10} // selection layer
                intensity={5.0} // The bloom intensity.
                width={Resizer.AUTO_SIZE} // render width
                height={Resizer.AUTO_SIZE} // render height
                kernelSize={KernelSize.LARGE} // blur kernel size
                luminanceThreshold={0} // luminance threshold. Raise this value to mask out darker elements in the scene.
                luminanceSmoothing={0} // smoothness of the luminance threshold. Range is [0, 1]
            />
            <SMAA />

        </EffectComposer>
    </Suspense>

}