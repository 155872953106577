import { createElement, useEffect, useRef, useState } from "react"
import { GiCoins, GiGasPump, GiHealthNormal, GiHeavyBullets, GiMissileSwarm, GiRocket, GiShield } from "react-icons/gi"
import { BufferGeometry, DataTexture2DArray, DoubleSide, Group, LinearFilter, LinearMipMapLinearFilter, Mesh, MeshBasicMaterial, NearestFilter, ShapeGeometry, Texture } from "three";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader"

const loadedGeometries = []
const loadedTextures:Texture[] = []
export const Textures: {
    [name: string]: any
} = {}
export const Geometries = {}

export default function IconSVGToTexture() {

    const [loaded, setLoaded] = useState(false)

    const icons = [{
        name: "ammo",
        ref: useRef<HTMLDivElement>(null),
        icon: GiHeavyBullets
    }, {
        name: "missiles",
        ref: useRef<HTMLDivElement>(null),
        icon: GiMissileSwarm
    }, {
        name: "fuel",
        ref: useRef<HTMLDivElement>(null),
        icon: GiGasPump
    }, {
        name: "sp",
        ref: useRef<HTMLDivElement>(null),
        icon: GiShield
    }, {
        name: "hp",
        ref: useRef<HTMLDivElement>(null),
        icon: GiHealthNormal
    }, {
        name: "coins",
        ref: useRef<HTMLDivElement>(null),
        icon: GiCoins
    }]

    useEffect(() => {

        let loadedIdx = 0

        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

        function load() {
            var ico = icons[loadedIdx]
            if (!ico) return
            if (!ico.ref.current) return
            var svg = ico.ref.current.children[0];
            svg.setAttribute("width", "128px")
            svg.setAttribute("height", "128px")
            svg.setAttribute("fill", "white")
            var svgData = (new XMLSerializer()).serializeToString(svg);
            var img = document.createElement("img");
            const src = "data:image/svg+xml;base64," + window.btoa(unescape(encodeURIComponent(svgData)))
            img.setAttribute("src", src);
            img.onload = function () {
                ctx.fillStyle = "#ffffff"
                ctx.drawImage(img, 0, 0);
                var texture = new Texture(img);
                texture.minFilter = texture.magFilter = LinearFilter
                texture.needsUpdate = true;
                loadedTextures.push(texture)
                Textures[ico.name] = texture
                loadedIdx++
                if (loadedTextures.length === icons.length) {
                    finished()

                } else load()
            }

        }

        function finished() {
            setLoaded(true)
        }

        load()

    }, [icons, setLoaded])

    return <div style={{ display: loaded ? "none" : "block" }}>
        {icons.map((icon, i) => {
            return <div key={i} ref={icon.ref} >
                <icon.icon />
            </div>
        })}
    </div>

}